@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

$MainColor: #E28020;
$DangerColor: #dc3545;
$popupSize: 450px;

* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset!important;

  .authenticated {
    padding-left: 57px;
  }
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: $MainColor;
  min-height: 64px;
  display: flex;
  justify-content: center;

  h1 {
    color: white;
    margin: 0;
    text-align: center;
    line-height: 64px;
    width: 100%;
    margin-right: 50px;
  }

  .logo {
    width: 64px;
    height: 64px;
    margin-left: 20px;
  }

  .MuiAppBar-colorPrimary {
    background-color: $MainColor;
  }

  .MuiListItemIcon-root {
    color: $MainColor;
  }

  .MuiDrawer-paperAnchorLeft {
    border-right: 1px solid $MainColor;

    .MuiList-padding {
      padding-top: 0;
    }
  }
}

.container-fluid {
  padding: 0 40px;
}

.upload-container {
  .upload-header {
    padding: 5px 10px;
  }
  .MuiCardHeader-avatar {
    line-height: 10px;
  }
}

.main-btn {
  background-color: $MainColor !important;
  color: white !important;
}

.pms-processing {
  .process-indicator {
    flex-grow: 1;
    width: 100%;
    text-align: center;
    label {
      font-size: 25px;
      color: $MainColor;
    }
  }
  .MuiPaper-root {
    max-width: 400px;
    width: 100%;
  }
  .MuiDialogTitle-root {
    text-align: center;
  }
  .MuiLinearProgress-root {
    height: 20px;
    background-color: rgba(32, 148, 243, 0.3);

    .MuiLinearProgress-bar {
      background-color: $MainColor;
    }
  }
  .MuiDialogActions-root {
    justify-content: center;
    button {
      background-color: $MainColor;
      color: white;
    }
  }
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: $MainColor!important;
}

.menu-selected {
  background-color: $MainColor !important;
  .MuiSvgIcon-root {
    color: white !important;
  }
  .MuiTypography-root {
    color: white !important;
  }
}

.MuiPickersToolbar-toolbar {
  background-color: $MainColor!important;
}

.MuiPickersDay-daySelected {
  background-color: $MainColor!important;
}

.swal2-container {
  z-index: 10000!important;
}

.ReactModal__Overlay {
  z-index: 10000!important;
}

.Mui-checked {
  path {
    fill: $MainColor;
  }
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
  h1 {
    color: $DangerColor;
  }
}