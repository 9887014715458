.group-input {
  background-color: white;
  border-radius: 5px;
  width: 100%;

  &.error {
    margin-bottom: 0;
    .MuiInputBase-formControl {
      border: 1px solid #f44336;
    }
  }

  .MuiInputBase-formControl {
    background-color: white;
    border-radius: 5px;
  }

  .MuiFilledInput-input {
    border-radius: 5px;
  }

  .MuiFilledInput-underline:before {
    border-bottom: 0px;
  }
}

.error-text {
  color: #f44336;
  font-size: 14px;
}
