@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.pms-button {
  text-align: center;
  margin: 0 auto;
}
.pms-button.loading {
  position: relative;
}
.pms-button.loading button {
  background-color: lightgray !important;
}
.pms-button.loading .loading-indicator {
  color: #E28020;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}